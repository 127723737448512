import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuServices } from '../appServices/menu.service';
import { IStore } from '../appModels/IStore';
import { StoreResolver } from './store.resolver';

@Injectable({
    providedIn: 'root'
})
export class MenuResolver {
    store: IStore;
    selectedMenuIndex = new BehaviorSubject(1);
    searchItem = new BehaviorSubject<string>(null);

    constructor(
        private _menuService: MenuServices,
        private _storeResolver: StoreResolver
    ) {
        this.subscribeSelectedStore();
    }

    
    /**
     * Subscribe Selected Store 
     */
    subscribeSelectedStore() {
        this._storeResolver.store.subscribe(res =>{
            this.store = res;
        });
    }


    /**
    * get menu
    */
    getMenu(menuId:number) {
        return this._menuService.getMenu(menuId, 'qrapp');
    }

    getProductByProductId(productId: number) {
        return this._menuService.getProductByProductId(productId);
    }
}